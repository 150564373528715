import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Article from 'components/Pages/Shared/Article';
import { ArticleContent } from 'components/Pages/Shared/ArticleContent';
import PageTitle from 'components/Pages/Shared/PageTitle';
import LegacyDisclaimer from 'components/Posts/LegacyDisclaimer';
import AssetModal from 'components/Modal/Asset';

import { elInView } from 'utils/scroll';
import DownloadableImagesModalOpenContext from 'context/DownloadableImagesModalOpenContext';
import PostHero from 'components/Posts/Hero';
import { decodeEntities } from 'utils/decode-wrapper';
import ThreeImageElement from 'components/ThreeImageElement';
import { DownloadableImages } from 'components/Posts/DownloadableImages';
import { debounce } from 'utils/debounce';
import UpNext from './Shared/UpNext';
import SeriesArchive from './Shared/SeriesArchive';
import RelatedPosts from './Shared/RelatedPosts';
import StickySocialButtons from './Shared/StickySocialButtons';
import SocialNav from './Shared/SocialNav';
import Meta from './Shared/Metadata';

export default function PostPageComponent({
	hero = {},
	post = {},
	isHomepage,
	shouldHideMeta = false,
}) {
	const [isDownloadAssetsModalOpen, setIsDownloadAssetsModalOpen] = useState(false);
	const [focusElement, setFocusElement] = useState(false);
	const isSeries = hero.series?.link && hero.series?.title;
	const relatedPosts =
		Array.isArray(post?.relatedPosts) && post.relatedPosts.length ? post.relatedPosts : [];

	const homepageClass = isHomepage ? 'is-homepage' : '';
	const featuredImageClass = hero.image?.id ? 'header-with--image' : '';
	const featuredVideoClass = hero.video?.wistiaUrl ? 'header-with--video' : '';
	const headerBgColorClass = `header-background--${hero.bgColor}`;
	const featuredImageLayoutClass = `header-layout--${hero.layout}`;

	const headerToolsRef = useRef(null);
	const socialStickysRef = useRef(null);
	const openModalContextValue = useMemo(() => {
		return {
			isDownloadAssetsModalOpen,
			setIsDownloadAssetsModalOpen,
			focusElement,
			setFocusElement,
		};
	}, [isDownloadAssetsModalOpen, setIsDownloadAssetsModalOpen, focusElement, setFocusElement]);

	const getArticleHeaderToolInView = () => {
		return elInView(headerToolsRef, true, 0.09, socialStickysRef);
	};

	const handleArticleHeaderToolScroll = useCallback(() => {
		// Bail if sticky social was already viewed
		if (socialStickysRef && socialStickysRef?.current?.classList?.contains('-viewed')) {
			return;
		}

		if (headerToolsRef) {
			window.requestAnimationFrame(() => {
				getArticleHeaderToolInView();
			});
		}
	}, []);

	/**
	 * Scroll Event
	 * 
	 * @todo The naming here is confusing. This logic controls the sticky social buttons but is named Article Header Tool.
	 */
	const debouncedHandleScroll = debounce(handleArticleHeaderToolScroll, 300);

	/**
	 * Add Scroll Event Listener for sticky social buttons and possibly more. See @todo above.
	 */
	useEffect(() => {
		window.addEventListener('scroll', debouncedHandleScroll);
	}, [debouncedHandleScroll]);

	const { meta, images, has_hero_block, meta: { hide_related_posts } } = post;
	const smallImageID =
		post.meta && post.meta.kdmfi_small_featured
			? post.meta.kdmfi_small_featured
			: 0;
	const wistiaUrl = post.wistia_video;
	const videoUrl =
		post.meta && post.meta.featured_video_url
			? post.meta.featured_video_url
			: '';
	const { hide_page_header: hidePageHeader } = meta || {};

	const isTermView = post.type === 'story_category_view';

	if (isTermView) {
		post.meta.header_background_color = 'green';
		post.meta.featured_image_layout = 'horizontal-above';
	}

	const imageBackgroundColorClass =
		post.meta && post.meta.header_background_color
			? `single-top article-hero--${post.meta.header_background_color}`
			: 'single-top';

	const imageData = hero.image;
	const smallImagedata = images ? images[smallImageID] : hero.image;

	const hasThreeImageLayout = meta && meta.featured_image_layout === '3-image';
	const verticalLayout = meta && meta.featured_image_layout === 'vertical';

	const displayFeaturedAboveTitle = meta && meta.featured_image_layout === 'horizontal-above';

	const portraitImage =
		meta && meta.portrait_featured_image ? images[meta.portrait_featured_image] : imageData;
	const leftImage = meta && meta.left_featured_image ? images[meta.left_featured_image] : false;
	const centerImage =
		meta && meta.center_featured_image ? images[meta.center_featured_image] : imageData;
	const rightImage =
		meta && meta.right_featured_image ? images[meta.right_featured_image] : false;

	const headerOrientation =
		meta && meta.featured_image_layout ? meta.featured_image_layout : false;

	const heroInner = '';

	const portraitImageObject =
		meta.portrait_featured_image &&
			typeof portraitImage?.media_details?.sizes?.full !== 'undefined'
			? {
				src: portraitImage.media_details.sizes.full.source_url,
				...portraitImage.media_details.sizes.full,
			}
			: null;

	return (
		<DownloadableImagesModalOpenContext.Provider value={openModalContextValue}>
			<Article>
				<div
					className={`content content--article ${headerBgColorClass} ${featuredImageLayoutClass} ${featuredImageClass} ${featuredVideoClass} ${homepageClass}`}
				>
					{(wistiaUrl || videoUrl) &&
						!isHomepage &&
						displayFeaturedAboveTitle &&
						!hidePageHeader && (
							<PostHero
								video={videoUrl}
								postTitle={decodeEntities(post.title.rendered)}
								location="single-top"
								wistiaUrl={wistiaUrl}
								orientation={headerOrientation}
							>
								{heroInner}
							</PostHero>
						)}

					{imageData &&
						!hasThreeImageLayout &&
						displayFeaturedAboveTitle &&
						!hidePageHeader && (
							<div className="article-hero-top">
								<PostHero
									image={imageData}
									smallImage={smallImagedata}
									postTitle={decodeEntities(post.title.rendered)}
									location={imageBackgroundColorClass}
									video={videoUrl}
									wistiaUrl={wistiaUrl}
									orientation={headerOrientation}
								>
									{heroInner}
								</PostHero>
							</div>
						)}

					{!isHomepage && !has_hero_block && !hidePageHeader && (
						<header className="article-header">
							{isSeries && <SeriesArchive {...hero.series} />}

							{!isHomepage && <PageTitle>{decodeEntities(hero.title)}</PageTitle>}

							{hero.isLegacyPost && <LegacyDisclaimer />}

							{hero.showMeta && !shouldHideMeta && <Meta post={post} />}

							<div
								id="article-header-tools"
								className="article-header__tools"
								ref={headerToolsRef}
							>
								{hero.downloadableAssets?.length ? (
									<>
										<AssetModal
											className="article-header__download"
											downloadableAssets={hero.downloadableAssets}
										/>
										<span className="article-header__divider">&#8226;</span>
									</>
								) : null}

								<SocialNav post={post} label="Article Header" />
							</div>
						</header>
					)}

					{(wistiaUrl || videoUrl) &&
						!isHomepage &&
						!displayFeaturedAboveTitle &&
						!verticalLayout &&
						!hidePageHeader && (
							<PostHero
								video={videoUrl}
								postTitle={decodeEntities(post.title.rendered)}
								location="single-bottom"
								wistiaUrl={wistiaUrl}
								orientation={headerOrientation}
							>
								{this.props.heroInner}
							</PostHero>
						)}

					{(imageData || portraitImage) &&
						!hasThreeImageLayout &&
						!displayFeaturedAboveTitle &&
						!hidePageHeader &&
						verticalLayout && (
							<PostHero
								image={portraitImage ? portraitImageObject : imageData}
								smallImage={smallImagedata}
								postTitle={decodeEntities(post?.title?.rendered)}
								location="single-bottom"
								video={videoUrl}
								wistiaUrl={wistiaUrl}
								orientation={headerOrientation}
							>
								{heroInner}
							</PostHero>
						)}

					{imageData &&
						!hasThreeImageLayout &&
						!displayFeaturedAboveTitle &&
						!hidePageHeader &&
						!verticalLayout && (
							<PostHero
								image={imageData}
								smallImage={smallImagedata}
								postTitle={decodeEntities(post?.title?.rendered)}
								location="single-bottom"
								video={videoUrl}
								wistiaUrl={wistiaUrl}
								orientation={headerOrientation}
							/>
						)}

					{hasThreeImageLayout && (
						<ThreeImageElement
							leftImage={leftImage}
							centerImage={centerImage}
							rightImage={rightImage}
						/>
					)}

					{(imageData || wistiaUrl || videoUrl) &&
						!isTermView &&
						!hidePageHeader &&
						!has_hero_block && <hr className="article-header-separator" />}

					{/* New Block Parser */}
					<DownloadableImages post={post}>
						<ArticleContent
							wrapperProps={{ className: 'article__content' }}
							post={post}
						/>
					</DownloadableImages>

					{/* Legacy Block Parser */}
					{/* <Blocks post={post} /> */}

					<StickySocialButtons post={post} ref={socialStickysRef} />
				</div>

				{relatedPosts.length > 0 && !hide_related_posts ? (
					<RelatedPosts
						posts={relatedPosts}
						type="related"
						title="More Like This"
						variationProps={{}}
					/>
				) : null}

				{isSeries && <UpNext series={hero.series} posts={post.adjacentPosts} />}
			</Article>
		</DownloadableImagesModalOpenContext.Provider>
	);
}

PostPageComponent.propTypes = {
	hero: PropTypes.object,
	post: PropTypes.object,
	isHomepage: PropTypes.bool,
};
